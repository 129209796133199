<template>
  <el-dialog :title=" '创建手动录入表单'" :close-on-click-modal="false" :visible.sync="visible">

    <div class="">
        <el-form :model="dataForm" ref="df">
            <el-form-item label="表名">
                <el-input v-model="dataForm.tableName" placeholder="请输入表名" class="inputClass"></el-input>
            </el-form-item>
            <el-form-item
                v-for="(field, index) in dataForm.fields"
                :label="'字段' + (index+1)"
                :key="field.key"
                :prop="'fields.' + index + '.value'"
            >
                <el-input v-model="field.manualEntryFieldName" placeholder="请输入字段名" class="inputClass"></el-input>
                <!-- <el-input v-model="field.value" placeholder="请选择字段种类"></el-input> -->
                <el-select v-model="field.manualEntryFieldType" placeholder="请选择字段种类">
                    <el-option
                        v-for="item in fieldTypes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">

                    </el-option>
                </el-select>
                <el-button @click.prevent="removeField(field)">删除字段</el-button>
            </el-form-item>

            <el-form-item>
                <el-button @click="addField">新增字段</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="createManualEntry()">创建</el-button>

            </el-form-item>
        </el-form>

  </div>
  </el-dialog>
</template>

<script>


export default {
  name:'Simple',
  components:{

  },
  data() {
    return {
      visible: false,
      code:'',
      fileList: [],
      dataForm:{
          tableName:'',
          fields:[
              {manualEntryFieldName:'',manualEntryFieldType:''}
          ]
      },
      fieldTypes:[
          {value:'1',label:'文本'},
        //   {value:'2',label:'文件'}
      ],
      checkedDetail:[

      ],
      dataRule:{
          tableName:[
              { required: true, message: "名称不能为空", trigger: "blur" },
          ]
      }
    };
  },
  created(){
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.visible=true;
      this.dataForm.tableName='';
      this.dataForm.fields=[{manualEntryFieldName:'',manualEntryFieldType:''}];
    },
    //新增字段
    addField(){
        this.dataForm.fields.push({
            manualEntryFieldName:'',
        });
    },
    //删除字段
    removeField(item){
        var index = this.dataForm.fields.indexOf(item);
        if(index!=-1){
            this.dataForm.fields.splice(index,1);
        }
    },
    //创建手动录入表
    createManualEntry(){
        //判断是信息是否完整
        var complete=true;
        if(this.dataForm.tableName==''){
            complete=false;
        }
        for(var i=0;i<this.dataForm.fields.length;i++){
            if(this.dataForm.fields[i].manualEntryFieldName.length==''||this.dataForm.fields[i].manualEntryFieldType==''){
                complete=false;
            }

        }
        if(!complete){
            //如果有未填的信息，弹框提示
            this.$message({
                message:'请补全信息',
                type:'error'
            });
        }else{
            let data={
                tableName:this.dataForm.tableName,
                fields:this.dataForm.fields,
            }
            this.postRequest("/manualEntry/createManualEntry",data).then(response=> {
              if (response) {
                this.$parent.initData();
                this.visible=false;
                this.$emit("initData")
              }
                /* if(response && response.code === 0){
                    this.$message({
                        message:'操作成功',
                        type:'success'
                    });
                }  */
            }).then(error => {
                // console.log(error)
            })
        }


    },

    rowClassName({ row, rowIndex }) {

        row.xh = rowIndex + 1;
    },


    //录入信息时新增一条
    handleAddDetails() {
      if (this.testFormMessage == undefined) {
        this.testFormMessage = new Array();
      }
      let obj = {};
      for(var i=0;i<this.testForm.length;i++){
          this.$set(obj,this.testForm[i].fieldName,"");
      }
      this.testFormMessage.push(obj);
    },

    //删除
    removeShowList(index,row){
        this.testFormMessage.splice(index,1);
    },
    //查看输入的数据
    showTestMessage(){
        console.log(this.testFormMessage);
    },
    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    handlePreview(file) {
        console.log(file);
    },
    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
      // 文件改变时
    /* fileChange(file, fileList) {
        this.fileList=fileList;
        this.fileList.append(file);
    }, */
  },
};
</script>
<style scoped>
.inputClass{
    width: 250px;
}
.el-input {
    margin-right: 10px;
}
.el-select {
    width: 250px;
    margin-right: 10px;
}
.warningButton{
  color: #F56C6C;
}
</style>
