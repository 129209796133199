<template>
  <div>
    
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>管道管理</el-breadcrumb-item>
      <el-breadcrumb-item>手动录入列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <span v-if="setting.advancesearch" style="font-size: 10px;">搜索：</span>
    <el-select v-if="setting.advancesearch"  v-model="currentCoalId" placeholder="请选择项目" @change="handleChange()" style="width: 400px" clearable>
      <el-option v-for="item in coaloptions" :key="item.id" :label="item.coal" :value="item.id">
      </el-option>
    </el-select> -->
    <el-button style="margin-left:10px" type="primary" @click="createManualEntryForm()">创建手动录入表单</el-button>

    <!--<el-button @click="test">test</el-button>-->
    <br/>
    <!--			<span style="font-size: 12px;">{{this.currentCoal}}概述：</span>-->
    <!--			<div class="coalblock" v-for="item in options" :key="item.title">-->
    <!--				<span style="font-size: 12px; color:#9196a1 ;">{{item.title}}</span>-->
    <!--				<br/>-->
    <!--				<span style="font-size: 24px;">{{item.num}}</span>-->
    <!--			</div>-->
<!--    <coal-over-view></coal-over-view>-->

    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
    >
      <el-table-column
          prop="id"
          label="手动录入表id"
          header-align="center"
          align="center"
          min-width="5%"
      >
      
      </el-table-column>
      <el-table-column
          prop="manualEntryTableName"
          label="手动录入表名称"
          header-align="center"
          align="center"
          min-width="5%" >
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="openManualEntryEdit(scope.row.id)"
          >修改</el-button>
          <el-button
		      :disabled = "scope.row.mapped == 0"
              type="text"
              size="small"
              @click="openManualInputData(scope.row.id)"
          >录入</el-button>
		      <el-button
		          type="text"
		          size="small"
		          @click="openManualMappingField(scope.row.id, scope.row.mapped)"
		      >{{ scope.row.mapped == 0?'映射':'修改映射' }}</el-button>
          <el-button
              type="text"
              class="warningButton"
              size="small"
              @click="handleDelete(scope.row.id)"
          >删除</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页区域 -->
    <div style="display: flex;justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total">
      </el-pagination>
    </div>
    <CreateManualEntryForm v-if="CreateManualEntryFormFlag" ref="createManualEntryForm" @initData="initData"></CreateManualEntryForm>
    <ManualEntryInputData v-if="ManualEntryInputDataFlag" ref="manualEntryInputData" :manualEntryId="manualEntryId"></ManualEntryInputData>
	<ManualEntryFieldMapping @update="initData" v-if="ManualEntryFieldMappingFlag" ref="manualEntryFieldMapping" :manualEntryFieldMappingId="manualEntryFieldMappingId" :mapped="mapped"></ManualEntryFieldMapping>
    <ManualEntryEdit v-if="ManualEntryEditFlag" ref="manualEntryEdit" :manualEntryEditId="manualEntryEditId" @initDataByEdit="initDataByEdit"></ManualEntryEdit>
  </div>
  

</template>

<script>
import CoalOverView from "../../coalSystemManage/coalOverView";
import OverViewOfCoal from "../../coalSystemManage/overViewOfCoal";
import OverViewOfSubSystem from "../../coalSystemManage/overViewOfSubSystem";
import CreateManualEntryForm from "./createManualEntryForm";//创建手动录入表单
import ManualEntryInputData from "./manualInputData";//手动录入数据
import ManualEntryFieldMapping from "./manualEntryFieldMapping";//手动录入数据
import ManualEntryEdit from "./manualEntryEdit";//修改表单信息
function changeFrameHeight(){
            var iframe= document.getElementById("myiframe");
            iframe.height = iframe.contentDocument.body.clientHeight;
        }
        window.οnresize=function(){
            changeFrameHeight();
        }
export default {
  name: "dataList",
  components: {
    OverViewOfSubSystem,
    OverViewOfCoal,
    CoalOverView,
    CreateManualEntryForm,
    ManualEntryInputData,
	ManualEntryFieldMapping,
    ManualEntryEdit
  },

  data() {
    var jwt = require("jsonwebtoken");

    var METABASE_SITE_URL = "http://localhost:3000";
    var METABASE_SECRET_KEY = "18fd60eca9c2914f0c788e0a77cd63babd536a362d975e683d96791c201866eb";
    var payload = {
      resource: { dashboard: 1 },
      params: {},
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };
    var token = jwt.sign(payload, METABASE_SECRET_KEY);
    var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false";
    return {
      name:'数据列表',
      currentCoal:"",
      coaloptions: [],
      currentCoalId: '',
      iframeUrl: iframeUrl,
      tableData: [],
      page:1,  //当前第几页
      size:10, //当前每页页数
      total:0, //全部数据量
      setting:{
        batchRemove:true,
        advancesearch:true,

      },

      currOperator: '',
      currOperatorName: '',

      dataListLoading: false,
      overViewFlag: false,
      CreateManualEntryFormFlag: false,
      ManualEntryInputDataFlag: false,
	  ManualEntryFieldMappingFlag: false,
      ManualEntryEditFlag: false,
      manualEntryId:0,
	  manualEntryFieldMappingid:0,
    mapped:0,
      manualEntryEditId:0
    }
  },
  mounted(){
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    // this.getCoalOption();
    // this.currentCoal = this.coaloptions[0].coal; //将当前项目与id设置为下拉框第一个
    // this.currentCoalId = this.coaloptions[0].id;
    this.initData();
    //根据名字查询系统设置中的结果
    this.getRequest('/systemSetting/getSettingInfoByName/?name='+this.name).then(resp=>{
      if (resp.data){
        // console.log(this.name)
        // console.log(resp.data)
        this.setting.batchRemove = resp.data.batchremove;
        this.setting.advancesearch = resp.data.advancesearch;
        this.size = resp.data.numberperpage;

      }
    });
  },
  methods: {
    //打开手动创建表单框
    createManualEntryForm(){
      this.CreateManualEntryFormFlag = true;
      this.$nextTick(() => {
        this.$refs.createManualEntryForm.init();
      });
    },
    //打开录入数据框
    openManualInputData(id){
      this.manualEntryId=id;
      //console.log(this.manualEntryId);
      this.ManualEntryInputDataFlag=true;
      this.$nextTick(() => {
        this.$refs.manualEntryInputData.init();
      });
    },
	//打开映射信息框
	openManualMappingField(id, mapped){
	  this.manualEntryFieldMappingId=id;
    this.mapped = mapped;
	  this.ManualEntryFieldMappingFlag=true;
	  this.$nextTick(() => {
	    this.$refs.manualEntryFieldMapping.init(id,mapped);
	  });
	},
    //打开修改表单信息框
    openManualEntryEdit(id){
      this.manualEntryEditId=id;
      console.log(this.manualEntryEditId);
      this.ManualEntryEditFlag=true;
      this.$nextTick(() => {
        this.$refs.manualEntryEdit.init();
      });
    },
    test(){
      for (let i in this.coaloptions) {
        if (this.coaloptions[i].id == this.currentCoalId){
          this.currentCoal = this.coaloptions[i].coal;
          break;
        }
      }
    },
    selectCoal(){
      for (let i in this.coaloptions) {
        if (this.coaloptions[i].id == this.currentCoalId){
          this.currentCoal = this.coaloptions[i].coal;
          break;
        }
      }
      this.initData();
    },
    getCoalOption(){
      this.getRequest('/dataList/getAllCoal/').then(resp=>{
        if (resp.data){
          this.coaloptions = resp.data;
        }
      });
    },
    initDataByEdit() {
      this.mapped = 1
      this.initData()
    },
    initData(){
      this.dataListLoading = true;
      this.getRequest(
          "/manualEntry/getManualEntryByPage/?page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp) {
          this.tableData = resp.data.data;
          console.log(resp.data);
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleChange() {
      this.dataListLoading = true
      if (this.currentCoalId != '') {
        this.getRequest(
            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +
            this.currentCoalId +
            "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
        });
      } else {
        this.getRequest(
            "/coalSystemManage/getCoalSystemInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
        });
      }
      this.dataListLoading = false
    },
    currentChange(currentPage){
      this.page=currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    datawatch(system) {
      // window.sessionStorage.setItem("dataList_currentSystemId",system.id); //存到sessionStorage中，下一个页面获取
      this.$router.push({
        name: 'checkData',
        query: {
          systemId: system.id
        }
      })
    },
    //删除手动录入表
    handleDelete(id){
      this.$confirm('此操作将删除该表单, 是否继续?','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getRequest(
          "/manualEntry/deleteManualEntry/?id=" +
          id
        ).then((resp) => {
          this.initData();
          if (resp) {
            if(resp && resp.code === 0){

            }else{

            }
          }
        });
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
      });
    }
  }
}
</script>

<style scoped>
.el-select {
  margin-top: 15px;
}
.el-button{
  margin-bottom: 15px;
}
</style>
